import React from "react";
import { Helmet } from "react-helmet";

function SeoSpace() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <title>monanosu</title>
      <meta name="description" content="monanosu space" />
      <link rel="shortcut icon" href="https://monanosu.kotaroo.work/favicon.png"></link>
      <meta property="og:url" content="https://monanosu.kotaroo.work/space" />
      <meta property="og:title" content="monanosu_space" />
      <meta property="og:image" content="https://monanosu.kotaroo.work/space_ogp.png" />
      <meta property="og:description" content="monanosu space" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="monanosu space" />
      <meta name="twitter:image" content="https://monanosu.kotaroo.work/space_ogp.png" />
      <meta name="twitter:description" content="monanosu space" />
      <meta name="twitter:site" content="@7101kerokero" />
    </Helmet>
  )
}
export default SeoSpace;
