import { functions } from "./Firebase";
import { httpsCallable } from "firebase/functions";
import { ITEMS_SPACE } from "../components/InitItemsSpace";

const coldFuncUP = httpsCallable(functions, "updateSpaceData");
const coldFuncDEL = httpsCallable(functions, "deleteSpaceData");

const coldFunc_1 = () => {
  coldFuncUP({ space_data: "lain" })
    .catch((e) => { console.log(e.message) });
}

const coldFunc_2 = () => {
  coldFuncDEL({ space_data: "lain" })
    .catch((e) => { console.log(e.message) });
}

/**
 * 
 */
export const bootReq = () => {
  if(ITEMS_SPACE.env === "l") return;
  coldFunc_1();
  coldFunc_2();
}