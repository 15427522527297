import { reqSpaceData } from "../../components/Firebase";
import SpaceManagedClass from "../../components/SpaceManagedClass";
import React, { useEffect, useState } from "react";
import MenuCreateEditSpace from "../../components/MenuCreateEditSpace";
import { initItemsSpace, ITEMS_SPACE } from "../../components/InitItemsSpace";
import { getAddressMP } from "../../components/Mpurse";
import SeoSpace from "../../components/SeoSpace";
import { checkServerResponse } from "../../components/MonapartyAPI";

const Edit = () => {

  const [ menuState, setMenuState ] = useState(false);
  const [ userAddr, setUserAddr ] = useState("");
  
  useEffect(() => {
    initFunc();
  }, []);

  async function initFunc() {
    if(ITEMS_SPACE.env === "t" || ITEMS_SPACE.env === "p") {
      const addressMP = await getAddressMP();
      if(addressMP === "") {
        if(typeof window !== `undefined`) { window.location.href = location.href.slice(0, -5) }
      }
      setUserAddr(addressMP);
      await reqSpaceData(addressMP);
    } else {
      setUserAddr(ITEMS_SPACE.envAddress);
      await reqSpaceData(ITEMS_SPACE.envAddress);
    }

    if(ITEMS_SPACE.spaceData === null) {
      ITEMS_SPACE.monaparty_server_url = await checkServerResponse();
      initItemsSpace("create");
      setMenuState(true);
    } else {
      ITEMS_SPACE.monaparty_server_url = await checkServerResponse();
      initItemsSpace("edit");
      setMenuState(true);
    }
  }
 
  if(!menuState) { return (<></>) }
  if(menuState) {
    return (
      <div className="m-0 overflow-hidden">
        <SeoSpace />
        <div id="front">
          <div className="z-50 h-screen">
            <MenuCreateEditSpace addr={ userAddr } />
          </div>
          <SpaceManagedClass />
        </div>
      </div>
    );
  }
}

export default Edit;